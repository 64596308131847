import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo-gs.webp'; // Import the new logo

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu visibility
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false); // Close the menu when a link is clicked
  };

  return (
    <nav className={`navbar ${isMenuOpen ? 'active' : ''}`}>
      <div className="navbardiv">
        <div className="logo">
          <Link to="/" onClick={handleLinkClick}>
            <img src={logo} alt="Radha IT Logo" className="navbar-logo" />
          </Link>
        </div>
        <div className="hamburger" onClick={handleHamburgerClick}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <ul className="nav-links">
          <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
          <li><Link to="/services" onClick={handleLinkClick}>Our Services</Link></li>
          {/* <li><Link to="/portfolio">Portfolio</Link></li> */}
          <li><Link to="/about" onClick={handleLinkClick}>About Us</Link></li>
          <li><Link to="/contact" onClick={handleLinkClick}>Contact Us</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
