import React, { useEffect } from 'react';
import itHardwareImage from '../assets/itHardware.webp';
import advice from '../assets/advice.webp';
import avGear from '../assets/aVSolutionsGear.webp';
import projectMgmt from '../assets/projectMgmt.webp';
import recruitment from '../assets/recruitment.webp';
import itSupport from '../assets/iTSupport.webp';
import cloudComputing from '../assets/cloudComputing.webp';
import network from '../assets/network.webp';
import security from '../assets/security.webp';


import './Services.css'; // Optional: Add custom styles for the page

const Services = () => {

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []); 

  return (
    <div className="services-container">
      <div className="services-containerdiv">
        <h1 className="services-title">Our Services</h1>
        <p className="services-intro">
          At Radha IT, we provide a wide range of IT and AV services to help your business thrive in today’s fast-paced technological landscape. Our goal is to offer customized solutions that align with your unique business needs. Below are the core services we specialize in:
        </p>

        {/* IT Procurement Service */}
        <div id="it-procurement" className="service-item">
          <div className="service-content">
            <img src={itHardwareImage} alt="IT Procurement" className="service-image" />
            <div className="service-text">
              <h2>IT Procurement</h2>
              <p>
                Our <strong>IT Procurement Services</strong> help businesses source the best technology solutions to meet their operational needs. From hardware to software, we manage the entire procurement process to ensure that you get the right products at the right price.
              </p>
              <ul>
                <li><strong>Hardware Procurement:</strong> We source a full range of IT hardware, including desktops, laptops, servers, networking equipment, and peripherals.</li>
                <li><strong>Software Procurement:</strong> We assist in selecting and acquiring the software you need, ensuring proper licensing and cost-efficiency.</li>
                <li><strong>Customized IT Solutions:</strong> Tailored procurement to match your specific business requirements, including bundling hardware and software solutions.</li>
                <li><strong>Vendor Management:</strong> We handle vendor relationships, negotiate prices, and ensure timely delivery of your IT products.</li>
                <li><strong>Sustainability in Procurement:</strong> We focus on environmentally-friendly solutions and help you responsibly manage the disposal of outdated hardware.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* AV Procurement, Consultation, and Installations */}
        <div id="av-solutions" className="service-item">
          <div className="service-content">
            <img src={avGear} alt="AV Procurement and Installation" className="service-image" />
            <div className="service-text">
              <h2>Audio-Visual (AV) Solutions</h2>
              <p>
                At Radha IT, we provide end-to-end solutions for your audio-visual needs. Whether you're looking to procure high-quality AV equipment, consult on the best AV setup for your business, or require professional installation, we have you covered. Our expertise spans conference room systems, interactive displays, digital signage, and more—ensuring seamless integration with your business goals.
              </p>
              <ul>
                <li><strong>AV Procurement:</strong> We help you source the best AV products, including projectors, screens, speakers, and more from trusted vendors.</li>
                <li><strong>AV Consultation:</strong> Our experts provide consultation to design the perfect AV solution tailored to your needs, ensuring optimal performance and user experience.</li>
                <li><strong>Conference Room AV Systems:</strong> We design and install state-of-the-art AV systems to enhance communication and collaboration in your meetings.</li>
                <li><strong>Digital Signage Solutions:</strong> We help you set up dynamic digital signage systems to engage customers and convey messages effectively.</li>
                <li><strong>Interactive Displays:</strong> Implement interactive screens for presentations, training, and collaborative workspaces to boost interactivity.</li>
                <li><strong>Audio and Video Conferencing Equipment:</strong> High-quality audio and video solutions to ensure smooth, clear, and professional virtual meetings.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* IT Infrastructure Consultation Service */}
        <div id="it-infrastructure" className="service-item">
          <div className="service-content">
            <img src={advice} alt="IT Infrastructure Consultation" className="service-image" />
            <div className="service-text">
              <h2>IT Infrastructure Consultation</h2>
              <p>
                Our IT experts provide consultation to design, implement, and optimize your business's IT infrastructure. We ensure that your systems are scalable, secure, and efficient, giving you the flexibility to grow and adapt to future needs.
              </p>
              <ul>
                <li>Network Design and Optimization</li>
                <li>Cloud Infrastructure Solutions</li>
                <li>Server and Data Center Planning</li>
                <li>Security Assessments and Compliance</li>
              </ul>
            </div>
          </div>
        </div>

        {/* IT Support Services */}
        <div id="it-support" className="service-item">
          <div className="service-content">
            <img src={itSupport} alt="IT Support Services" className="service-image" />
            <div className="service-text">
              <h2>IT Support Services</h2>
              <p>
                Keep your systems running smoothly with our ongoing IT support. We offer both remote and on-site support, troubleshooting, system monitoring, and preventative maintenance to minimize downtime and improve productivity.
              </p>
              <ul>
                <li>Remote IT Support</li>
                <li>On-site Troubleshooting</li>
                <li>System Monitoring and Maintenance</li>
                <li>Help Desk Services</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Security and Disaster Recovery Solutions */}
        <div id="security-solutions" className="service-item">
          <div className="service-content">
            <img src={security} alt="Security and Disaster Recovery" className="service-image" />
            <div className="service-text">
              <h2>Security and Disaster Recovery Solutions</h2>
              <p>
                Safeguard your business from cyber threats and ensure business continuity with our comprehensive security and disaster recovery services. From protecting your systems and data with advanced security measures to planning for unexpected events, we offer solutions that ensure your operations remain secure and resilient.
              </p>
              <ul>
                <li><strong>Firewall Installation and Configuration:</strong> Protect your network with robust firewalls.</li>
                <li><strong>Data Encryption:</strong> Secure sensitive data both in transit and at rest.</li>
                <li><strong>Endpoint Security Solutions:</strong> Ensure all devices within your network are protected from threats.</li>
                <li><strong>Security Audits and Vulnerability Assessments:</strong> Identify weaknesses and enhance your security posture.</li>
                <li><strong>Backup Solutions:</strong> Ensure data integrity with reliable backup solutions.</li>
                <li><strong>Business Continuity Planning:</strong> Create a strategy to minimize downtime and maintain operations.</li>
                <li><strong>Data Recovery Services:</strong> Quickly recover data in the event of a system failure.</li>
                <li><strong>Cloud-Based Disaster Recovery Solutions:</strong> Implement scalable and secure cloud-based recovery options.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* IT Recruitment */}
        <div id="recruitment" className="service-item">
          <div className="service-content">
            <img src={recruitment} alt="IT Recruitment" className="service-image" />
            <div className="service-text">
              <h2>IT Recruitment</h2>
              <p>
                We specialize in recruiting top-tier IT professionals to meet your company's specific staffing needs. Whether you need temporary staff or full-time hires, we connect you with skilled professionals that align with your business goals.
              </p>
              <ul>
                <li>Temporary and Permanent IT Staffing</li>
                <li>Recruitment for Development, Support, and Operations Roles</li>
                <li>Talent Sourcing and Screening</li>
                <li>Outsourcing IT Recruitment</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Project Management */}
        <div id="project-management" className="service-item">
          <div className="service-content">
            <img src={projectMgmt} alt="Project Management" className="service-image" />
            <div className="service-text">
              <h2>Project Management</h2>
              <p>
                Our project management services ensure the successful delivery of your IT projects. We manage timelines, budgets, and resources to ensure that your projects are completed on time, within budget, and to your satisfaction.
              </p>
              <ul>
                <li>End-to-End Project Management</li>
                <li>Agile and Waterfall Methodologies</li>
                <li>Project Scheduling and Budget Management</li>
                <li>Risk Management and Mitigation</li>
                <li>Project Reporting and Documentation</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Network Setup and Configuration */}
        <div id="networking-solutions" className="service-item">
          <div className="service-content">
            <img src={network} alt="Network Setup" className="service-image" />
            <div className="service-text">
              <h2>Network Setup and Configuration</h2>
              <p>
                We design and implement reliable, secure, and high-performance networks for businesses of all sizes. Whether you need a simple office network or a more complex enterprise solution, we ensure a seamless setup and ongoing support.
              </p>
              <ul>
                <li>Local Area Network (LAN) Setup</li>
                <li>Wide Area Network (WAN) Configuration</li>
                <li>Wireless Network Setup</li>
                <li>Network Security Solutions</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Cloud Services */}
        <div id="cloud-services" className="service-item">
          <div className="service-content">
            <img src={cloudComputing} alt="Cloud Services" className="service-image" />
            <div className="service-text">
              <h2>Cloud Services</h2>
              <p>
                Leverage the power of the cloud with our customized cloud solutions. From cloud storage to virtual machines and backup solutions, we ensure your business has access to secure, scalable, and cost-effective cloud infrastructure.
              </p>
              <ul>
                <li>Cloud Hosting and Storage</li>
                <li>Cloud Backup and Recovery</li>
                <li>Virtualization Services</li>
                <li>Software-as-a-Service (SaaS) Solutions</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
