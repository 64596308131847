import React, { useState } from 'react';
import * as emailjs from 'emailjs-com'; // Import emailjs-com
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    const { name, email, message } = formData;

    // Send the email using emailjs
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        { name, email, message },
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response);
          setShowSuccessModal(true); // Show the success modal
          resetForm(); // Reset the form fields
        },
        (error) => {
          console.log('FAILED...', error);
          setShowErrorModal(true); // Show the error modal
        }
      );
  };

  // Reset form data
  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false); // Close the success modal
    setShowErrorModal(false); // Close the error modal
  };

  return (
    <div className="contact-form">
      <h2>Contact Us</h2>
      <p>We'd love to hear from you! Fill out the form below, and we'll get back to you shortly.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Your Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Your Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Your Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Write your message here"
            rows="5"
            value={formData.message}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
        <button type="submit">Send Message</button>
      </form>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal success-modal">
          <div className="modal-content">
            <h3>Thank you for reaching out!</h3>
            <p>We appreciate your message and will get back to you shortly. If you need immediate assistance, feel free to give us a call.</p>
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {showErrorModal && (
        <div className="modal success-modal">
          <div className="modal-content">
            <h3>Oops! Something went wrong.</h3>
            <p>
              Unfortunately, we couldn't deliver your message. Please email us at <strong>info@radhait.ca</strong> or call us directly for assistance.
            </p>
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
