import React from 'react';
import { Link } from 'react-router-dom';
import './Hero.css';
import logo from '../assets/logo.png';

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <div className="hero-title">
          <h1>Welcome to</h1>
          <img src={logo} alt="Radha IT Logo" className="hero-logo" />
        </div>
        <p className="hero-description">
          Your trusted partner for IT and AV solutions. <br/> We specialize in IT and AV solutions, providing consulting, procurement, and support tailored to your needs.
        </p>
        <Link to="/contact" className="hero-button">
          Contact Us for a Free Consultation
        </Link>
      </div>

      {/* Services Overview Section */}
      <div className="services-overview">
        <h2>Our Services</h2>
        <div className="service-links">
          <div className="service-link">
            <Link to="/services#it-procurement">
              <h3>IT Procurement</h3>
              <p>We source the best hardware and software to meet your business needs, ensuring cost-efficiency and high performance.</p>
            </Link>
          </div>
          <div className="service-link">
            <Link to="/services#av-solutions">
              <h3>Audio-Visual Solutions</h3>
              <p>End-to-end AV solutions for your office, including installation, consultation, and equipment sourcing.</p>
            </Link>
          </div>
          <div className="service-link">
            <Link to="/services#it-infrastructure">
              <h3>IT Infrastructure Consultation</h3>
              <p>We optimize your IT infrastructure to be secure, scalable, and efficient for your growing business.</p>
            </Link>
          </div>
          <div className="service-link">
            <Link to="/services#it-support">
              <h3>IT Support Services</h3>
              <p>Comprehensive IT support, including remote and on-site troubleshooting, to keep your systems running smoothly.</p>
            </Link>
          </div>
          <div className="service-link">
            <Link to="/services#security-solutions">
              <h3>Security & Disaster Recovery</h3>
              <p>Advanced security solutions and disaster recovery plans to ensure your business stays secure and resilient.</p>
            </Link>
          </div>
          <div className="service-link">
            <Link to="/services#recruitment">
              <h3>IT Recruitment</h3>
              <p>Find skilled IT professionals with our expert recruitment services to meet your growing team’s needs.</p>
            </Link>
          </div>
          <div className="service-link">
            <Link to="/services#project-management">
              <h3>Project Management</h3>
              <p>Efficient project management to ensure smooth execution of your IT and AV projects, on time and within budget.</p>
            </Link>
          </div>
          <div className="service-link">
            <Link to="/services#networking-solutions">
              <h3>Networking Solutions</h3>
              <p>Reliable network setup and configuration to ensure high performance and secure communication for your business.</p>
            </Link>
          </div>
          <div className="service-link">
            <Link to="/services#cloud-services">
              <h3>Cloud Services</h3>
              <p>Cost-effective and scalable cloud solutions for storage, backup, and computing needs.</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
