import React, { useState, useEffect } from 'react';
import './About.css';
import image1 from '../assets/advice.webp'; // Replace with actual file names
import image2 from '../assets/aVSolutionsGear.webp';
import image3 from '../assets/cloudComputing.webp';
import image4 from '../assets/itHardware.webp';
import image5 from '../assets/iTSupport.webp';
import image6 from '../assets/logo.png';
import image7 from '../assets/network.webp';
import image8 from '../assets/projectMgmt.webp';
import image9 from '../assets/ledDisplay.webp';
import image10 from '../assets/recruitment.webp';
import image11 from '../assets/logo-short.png';
import image12 from '../assets/security.webp';

const About = () => {
  const imageGroups = [
    [image1, image2, image3],
    [image4, image5, image6],
    [image7, image8, image9],
    [image10, image11, image12],
  ];

  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentGroupIndex((prevIndex) => (prevIndex + 1) % imageGroups.length);
    }, 10000); // Change images every 10 seconds
    return () => clearInterval(interval);
  }, [imageGroups.length]);

  return (
    <div className="about-container">
      <div className="about-containerdiv">
        <div className="about-content">
          <h2>About Us</h2>
          <p>
            At Radha IT, we are passionate about delivering cutting-edge IT and AV solutions to businesses of all sizes. 
            With expertise in IT hardware supplies, audio-visual installations, and personalized consultations, 
            we help you achieve your technology goals seamlessly.
          </p>
          <p>
            Our mission is to provide reliable, high-quality solutions that align with your business needs. 
            From upgrading your IT infrastructure to designing state-of-the-art AV setups, we are your trusted partner.
          </p>
          <p>
            With a client-first approach, we focus on understanding your unique challenges and providing 
            tailored solutions to enhance productivity and performance.
          </p>
        </div>
        <div className="about-image-row">
          {imageGroups[currentGroupIndex].map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slideshow ${index + 1}`}
              className="about-image"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
